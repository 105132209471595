import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { api_url, databaseUrl } from "../../utils/data";

import ArrearsPayment from "./ArrearsPayment";
import { IoPrintSharp } from "react-icons/io5";
import LogoSmall from "../../assets/rxsmall.jpeg"
import { useAuthenticate } from "../../context/AuthContext";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";


const SaleDetails = () => {
  const {sale_id} = useParams()
  const {authToken, subscriptionDetails} = useAuthenticate()
  const [saleDetails, setSaleDetails] = useState()
  const [loadingDetails, setLoadingDetails] = useState(true)
  const [arrearsPaymentModal, setArrearsPaymentModal] = useState(false)

  const getSaleDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/sales/${sale_id}/details`, config)
      .then((response) => {
        setSaleDetails(response.data);
        setLoadingDetails(false)
      })
      .catch((error) => {
        setLoadingDetails(false)
      });
  }

  useEffect(()=>{
    
    getSaleDetails();
  },[])
  if(loadingDetails){
    return <Loading />
  }else{
    return (
      <main id="content" role="main" className="main main-page pointer-event">
        <div className="content container-fluid">
          <div className="page-header d-flex justify-content-between">
            <h1 className="page-header-title">
              <span> Sale details </span>
            </h1>
          </div>
          <div className="row" id="printableArea">
            <div className="col-lg-12 order-print-area-left">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header flex-wrap align-items-start border-0">
                  <div className="order-invoice-left">
                    <h1 className="page-header-title">
                      <span className="mr-3">
                        Invoice ID #{saleDetails?.sale_id}
                      </span>
                      {/* <span className="badge badge-soft-info py-2 px-3">
                        Main
                      </span> */}
                    </h1>
                    <span>
                      <i className="tio-date-range" /> {saleDetails?.date}{" "}
                      {saleDetails?.time}
                    </span>
                  </div>
                  <div className="order-invoice-right mt-3 mt-sm-0">
                    <div className="btn--container ml-auto align-items-center justify-content-end">
                      <Link
                        className="btn btn--info print--btn"
                        target="_blank"
                        to={`/sales/${saleDetails?.id}/receipt`}
                      >
                        <i className="tio-print mr-1" /> Print Invoice
                      </Link>
                    </div>
                    {saleDetails?.payment_status === "Part Payment" && (
                      <div className="btn--container ml-auto align-items-center justify-content-end">
                        <Link
                          className="btn btn-outline-info"
                          onClick={()=>setArrearsPaymentModal(true)}
                        >
                          <i className="tio-print mr-1" /> Receive Arrears Payment
                        </Link>
                      </div>
                    )}
                    <div className="text-right mt-3 order-invoice-right-contents text-capitalize">
                      
                      <h6>
                        <span className="text-body mr-2">
                          Payment Status :{" "}
                        </span>
                        {saleDetails?.payment_status === "Part Payment" ? (
                          <span className="badge badge-soft-danger ml-sm-3">
                            {saleDetails?.payment_status}
                          </span>
                        ) : (
                          <span className="badge badge-soft-success ml-sm-3">
                            {saleDetails?.payment_status}
                          </span>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-borderless table-nowrap table-align-middle card-table dataTable no-footer mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">SL</th>
                          <th className="border-0">Medicine details</th>
                          <th className="border-0 text-right">Unit Price</th>
                          <th className="border-0 text-right">Quantity</th>
                          <th className="text-right border-0">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {saleDetails?.sale_items.map((saleItem, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="media media--sm">
                                
                                <div className="media-body">
                                  <h5 className="line--limit-1">
                                    {saleItem?.drug_name}
                                  </h5>
                                  
                                </div>
                              </div>
                            </td>
                            <td className="text-right">
                              <h6>GHS {saleItem?.drug_final_price}</h6>
                            </td>
                            <td className="text-right">
                              <h6> {saleItem?.quantity}</h6>
                            </td>
                            <td className="text-right">
                              <h5>GHS {saleItem?.total_cost}</h5>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={12} className="td-p-0">
                            <hr className="m-0" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row justify-content-md-end mb-3 mt-4">
                    <div className="col-md-9 col-lg-8">
                      <dl className="row text-right justify-content-end">
                        

                        <dt className="col-6 text-left">
                          <div className="ml-auto max-w-130px">Total:</div>
                        </dt>
                        <dd className="col-6 col-xl-5 pr-5">
                          GHS {saleDetails?.total_cost}
                          <hr />
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        {/* ARREARS PAYMENTS */}
        <div className="content container-fluid">
          <div className="page-header d-flex justify-content-between">
            <h1 className="page-header-title">
              <span> Payments History </span>
            </h1>
          </div>
          <div className="row" id="printableArea">
            <div className="col-lg-12 order-print-area-left">
              <div className="card mb-3 mb-lg-5">
                
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-borderless table-nowrap table-align-middle card-table dataTable no-footer mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">SL</th>
                          <th className="border-0">Payment ID</th>
                          <th className="border-0 ">Amount Paid</th>
                          <th className="border-0 ">Outstanding Balance</th>
                          <th className=" border-0">Date Paid</th>
                          <th className=" border-0">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          saleDetails?.sale_payments?.map((payment, index)=>(
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {payment.payment_id}
                                </td>
                                <td>
                                  GHS {payment.amount_paid}
                                </td>
                                <td>
                                  GHS {payment.outstanding_balance}
                                </td>
                                <td>
                                  {payment.date}
                                </td>
                                <td>
                                <Link
                              className="action-btn btn-outline-primary-2"
                              target="_blank"
                              to={`/sales/payment/${payment.id}/receipt`}
                            >
                              <IoPrintSharp />
                            </Link>
                                </td>
                            </tr>

                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        {
          arrearsPaymentModal && <ArrearsPayment sale = {saleDetails} setReceivePaymentModal={setArrearsPaymentModal} />
        }
        
        
      </main>
    );
  }
};

export default SaleDetails;
