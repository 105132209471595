import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import ReactPaginate from "react-paginate";
import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const MedicineStock = () => {
  const { authToken, pharmacyDetails } = useAuthenticate();
  const medicineListRef = useRef();
  const [drugList, setDrugList] = useState([]);
  const [allMedicine, setAllMedicine] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(""); // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalMedicine, setTotalMedicine] = useState(0);
  const [gettingMedicineList, setGettingMedicineList] = useState(true);

  const today = new Date().toLocaleDateString();

  const filterDrugList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const getAllMedicineList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/inventory/all-medicines/`, config)
      .then((response) => {
        setAllMedicine(response.data);
      })
      .catch((error) => {
        toast.error("There was an error getting your drug list");
      });
  };

  const getMedicineList = async (page, searchQuery) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/inventory/medicines/?page=${page}&search=${searchQuery}`,
        config
      )
      .then((response) => {
        setDrugList(response.data?.results);
        setMedicineList(response.data?.results);
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
        setTotalMedicine(response.data?.count);
        setGettingMedicineList(false);
      })
      .catch((error) => {
        setGettingMedicineList(false);
        toast.error("There was an error getting your drug list");
      });
  };

  const deleteMedicine = (medicine_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        axios
          .delete(`${api_url}/inventory/medicines/${medicine_id}/`, config)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Medicine has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => number !== "..." && handlePageClick(number)}
        className={
          number === currentPage
            ? "bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10"
            : "border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10"
        }
      >
        {number}
      </button>
    ));
  };

  useEffect(() => {
    getAllMedicineList();
  }, []);

  useEffect(() => {
    getMedicineList(currentPage, search);
  }, [currentPage, search]);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">Medicine List</span>
          </h1>
        </div>
        {gettingMedicineList ? (
          <Loading />
        ) : (
          <div className="card">
            <div className="card-body p-5px">
              <div className="order-top">
                <div className="card--header">
                  <form>
                    <div className="input-group">
                      <input
                        id="datatableSearch_"
                        type="search"
                        name="search"
                        className="form-control"
                        placeholder="Search by Drug Name or Generic Name"
                        aria-label="Search"
                        required
                        // onChange={(e)=>setSearch(e.target.value)}
                        onChange={(e) => filterDrugList(e.target.value)}
                      />
                      {/* <div className="input-group-append">
                          <button type="submit" className="input-group-text">
                            Search
                          </button>
                        </div> */}
                    </div>
                  </form>

                  {allMedicine.length > 0 && (
                    <div className="hs-unfold mr-2">
                      <ReactToPrint
                        trigger={() => (
                          <div className="btn btn-primary">
                            Print Medicine List
                          </div>
                        )}
                        content={() => medicineListRef.current}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive datatable-custom">
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">Medicine ID</th>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Cost Price</th>
                      <th>Selling Price</th>
                      <th>Markup rate(%)</th>

                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="set-rows">
                    {medicineList?.map((medicine, index) => (
                      <tr className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td className="table-column-pl-0">
                          <Link href="#">{medicine?.medicine_id}</Link>
                        </td>
                        <td>{medicine?.name}</td>
                        <td>
                          <span>{medicine?.quantity}</span>
                        </td>
                        <td>{medicine?.manufacturer_price}</td>
                        <td>{medicine?.final_price}</td>
                        <td>{medicine?.markup_rate}</td>

                        <td>
                          <div className="btn--container justify-content-center">
                            <Link
                              to={`/medicine/${medicine.id}/details`}
                              className="action-btn"
                              title="Edit"
                            >
                              <MdEdit />
                            </Link>
                            <Link className="action-btn btn--danger btn-outline-danger">
                              <MdDeleteOutline
                                onClick={() => deleteMedicine(medicine.id)}
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {medicineList?.length <= 0 && <NoData />}
              </div>
              {/* TABLE TO PRINT */}
              <div style={{ display: "none" }}>
                <div ref={medicineListRef} className="py-5 px-2">
                  <div className="text-center border-b border-slate-200 pb-4 mb-4">
                    <h1 className="text-[35px] m-0">
                      {pharmacyDetails?.pharmacy_name}
                    </h1>
                    <p className="m-0">{pharmacyDetails?.pharmacy_location}</p>
                    <p className="m-0">
                      {pharmacyDetails?.pharmacy_email}{" "}
                      {pharmacyDetails?.pharmacy_phone}
                    </p>
                  </div>

                  <p>Date Printed: {new Date().toLocaleDateString()}</p>
                  <div  className="mt-7">
                    <h3 className="text-center pb-3">Medicine List</h3>
                    <table
                      className="table table-hover table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
                      style={{ width: "100%" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="">SL</th>
                          <th>Medicine ID</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Cost Price</th>
                          <th>Selling Price</th>
                          <th>Markup rate</th>
                        </tr>
                      </thead>
                      <tbody id="set-rows">
                        {allMedicine?.map((medicine, index) => (
                          <tr className="status-delivered class-all">
                            <td className="">{index + 1}</td>
                            <td>{medicine.medicine_id}</td>
                            <td>{medicine.name}</td>

                            <td>
                              <span>{medicine.quantity}</span>
                            </td>
                            <td>
                              <span>{medicine.manufacturer_price}</span>
                            </td>
                            <td>
                              <div>{medicine.final_price}</div>
                            </td>
                            <td>
                              <div>{medicine.markup_rate}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer border-0">
              <div className="d-flex items-center justify-content-between">
                <p>
                  Page {currentPage} of {totalPages}
                </p>
                <div className="d-flex gap-3">
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !prevPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handlePreviousPage}
                    disabled={!prevPage}
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !nextPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handleNextPage}
                    disabled={!nextPage}
                  >
                    Next
                  </button>
                </div>
                {/* <nav>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="›"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="‹"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      activeClassName="page-item active"
                      pageLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                    
                  </nav> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default MedicineStock;
