import React, { useEffect, useState } from "react";
import { IoMdMenu } from "react-icons/io";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthenticate } from "../context/AuthContext";
import { IoIosNotifications } from "react-icons/io";
import axios from "axios";
import { api_url } from "../utils/data";
import { toast } from "react-toastify";

const Topbar = ({ openSidebar, setOpenSidebar }) => {
  const { user, pharmacyDetails, subscriptionDetails, authToken } =
    useAuthenticate();
  const [dropdown, setDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);

  const logoutUser = () => {
    Swal.fire({
      title: "Do you want to logout ",
      showDenyButton: true,
      confirmButtonColor: "#01684b",
      cancelButtonColor: "#363636",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("auth");
        window.location.href = "/";
      } else {
        Swal.fire({
          title: "Canceled",
          confirmButtonText: "Okay",
        });
      }
    });
  };

  const markAsRead = async (notification_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };
    const body = JSON.stringify({
      is_read: true,
    });
    await axios
      .put(
        `${api_url}/accounts/notifications/${notification_id}/`,
        body,
        config
      )
      .then((response) => {
        toast.success("Marked as read");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // if (subscriptionDetails) {
    //   if (!subscriptionDetails?.success) {
    //     window.location.href = "/no-subscription";
    //   }
    // }
  });

  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
    >
      <div className="navbar-nav-wrap">
        <div className="navbar-nav-wrap-content-left ">
          <button
            type="button"
            className=""
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <IoMdMenu className="" />
          </button>
        </div>

        <div className="navbar-nav-wrap-content-right">
          <ul className="navbar-nav align-items-center flex-row">
            {subscriptionDetails?.success && (
              <>
                <li class="nav-item">
                  <div className={`active-subscription hidden md:block ${subscriptionDetails?.subscription?.days_left > 20 && "safe"} ${subscriptionDetails?.subscription?.days_left <= 20 && "warning"} ${subscriptionDetails?.subscription?.days_left <= 5 && "danger"}`} >
                    <div className="active-subscription-title">
                      {" "}
                      {subscriptionDetails?.subscription?.package}
                    </div>
                    <span className="">
                      {subscriptionDetails?.subscription?.days_left} days remaining
                    </span>
                  </div>
                </li>
                {subscriptionDetails?.subscription?.package !== "Alpha" &&
                  subscriptionDetails?.subscription?.package !== "Compact" && (
                    <li class="nav-item">
                      <div class="hs-unfold">
                        <a
                          onClick={() => {
                            setNotificationDropdown(!notificationDropdown);
                            setDropdown(false);
                          }}
                          class="js-hs-unfold-invoker btn btn-icon notify--icon"
                          data-hs-unfold-invoker=""
                        >
                          <IoIosNotifications style={{ fontSize: "2rem" }} />
                          <span class="amount">
                            {pharmacyDetails?.notifications?.length}
                          </span>
                        </a>
                        {notificationDropdown && (
                          <div
                            id="accountNavbarDropdown"
                            className="hs-unfold-content dropdown-unfold dropdown-menu notification-dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account w-16rem hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp"
                          >
                            <div className="dropdown-divider" />
                            {pharmacyDetails?.notifications?.map(
                              (notification, index) => (
                                <>
                                  <div
                                    className={`dropdown-item ${
                                      !notification?.is_read && "font-bold"
                                    }`}
                                    key={index}
                                    onClick={() => markAsRead(notification.id)}
                                  >
                                    {notification?.details}
                                  </div>
                                  <div className="dropdown-divider" />
                                </>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  )}
              </>
            )}
            <li className="nav-item ml-4">
              <div className="hs-unfold">
                <a
                  onClick={() => {
                    setDropdown(!dropdown);
                    setNotificationDropdown(false);
                  }}
                  className="cursor-pointer js-hs-unfold-invoker navbar-dropdown-account-wrapper"
                >
                  <div className="cmn--media d-flex align-items-center">
                    <div className="media-body pl-0 pr-2">
                      <span className="card-title h5 text-right">
                        {" "}
                        {user?.first_name} {user?.last_name}{" "}
                      </span>
                      <span className="card-text">{user?.email}</span>
                    </div>
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        className="avatar-img"
                        src={
                          user?.profile_picture
                            ? `https://rxultimate.com/${user?.profile_picture}`
                            : "https://grofresh-admin.6amtech.com/storage/app/public/admin/2023-10-21-65335c1bd07bd.png"
                        }
                        alt="no data"
                      />

                      <span className="avatar-status avatar-sm-status avatar-status-success" />
                    </div>
                  </div>
                </a>
                {dropdown && (
                  <div
                    id="accountNavbarDropdown"
                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account w-16rem hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp"
                  >
                    <div className="dropdown-item-text">
                      <div className="media align-items-center">
                        <div className="avatar avatar-sm avatar-circle mr-2">
                          <img
                            className="avatar-img"
                            src={
                              user?.profile_picture
                                ? `${user?.profile_picture}`
                                : "https://grofresh-admin.6amtech.com/storage/app/public/admin/2023-10-21-65335c1bd07bd.png"
                            }
                            alt="no data"
                          />
                        </div>
                        <div className="media-body">
                          <span className="card-title h5">
                            {user?.first_name} {user?.last_name}
                          </span>
                          <span className="card-text">
                            <a className="__cf_email__">{user?.email}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider" />
                    <Link className="dropdown-item" to="/settings/profile">
                      <span className="text-truncate pr-2" title="Settings">
                        Profile
                      </span>
                    </Link>
                    <div className="dropdown-divider" />
                    <a
                      className="dropdown-item cursor-pointer"
                      onClick={() => logoutUser()}
                    >
                      <span className="text-truncate pr-2" title="Sign out">
                        Sign out
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
