import React from 'react'
import NoDataImg from "../assets/img/no_data.svg"

const NoData = () => {
  return (
    <div className="flex flex-col items-center text-center p-4">
        <img
        className="mb-3 w-120px"
        src={NoDataImg}
        alt="no data"
        />
        <p className="mb-0">No data to show</p>
    </div>
  )
}

export default NoData