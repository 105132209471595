import React, { useEffect, useRef, useState } from "react";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../utils/data";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";

const SalesHistory = () => {
  const { authToken, pharmacyDetails } = useAuthenticate();
  const salesListRef = useRef();
  const [loadingSaleHistory, setLoadingSaleHistory] = useState(false);
  const [loadingAllSales, setLoadingAllSales] = useState(false)
  const [saleHistory, setSaleHistory] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [deletingSale, setDeletingSale] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(""); // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalSales, setTotalSales] = useState(0);
  const [salesCount, setSalesCount] = useState(0)

  const filterSaleList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const getSaleHistory = async (page, searchQuery) => {
    setLoadingSaleHistory(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/sales/?page=${page}&search=${searchQuery}&start_date=${filterStartDate}&end_date=${filterEndDate}`, config)
      .then((response) => {
        setSaleHistory(response.data?.payload?.results);
        setNextPage(response.data?.payload?.next);
        setPrevPage(response.data?.payload?.previous);
        setTotalPages(Math.ceil(response.data?.payload?.count / 20));
        setTotalSales(response.data?.total_sales);
        setSalesCount(response.data?.payload?.count)
        setLoadingSaleHistory(false);
      })
      .catch((error) => {
        setLoadingSaleHistory(false);
      });
  };

  const getAllSales = async () => {
    setLoadingAllSales(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/sales/list/`, config)
      .then((response) => {
        setSalesList(response?.data)
        setLoadingAllSales(false);
      })
      .catch((error) => {
        setLoadingAllSales(false);
      });
  };


  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => number !== "..." && handlePageClick(number)}
        className={
          number === currentPage
            ? "bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10"
            : "border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10"
        }
      >
        {number}
      </button>
    ));
  };


  const deleteSale = (sale_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingSale(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${api_url}/sales/${sale_id}/details`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Sale has been deleted.",
                icon: "success",
              }).then(() => {
                window.location.reload();
                setDeletingSale(false);
              });
            });
        } catch (error) {
          setDeletingSale(false);
          toast.error("Something went wrong.");
        }
      }
    });
  };

  useEffect(()=>{
    getAllSales()
  },[])

  useEffect(() => {
    getSaleHistory(currentPage, search);
  }, [currentPage, search,filterStartDate,filterEndDate]);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">All Sales</span>
          </h1>
        </div>
        <div className="card mb-10px">
              <div className="card-body">
                <div className="row g-2" id="order_stats">
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/medicine/list">
                      <h6 className="subtitle">Total Sales</h6>
                      <h2 className="title">{totalSales}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/pending.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/sales/list">
                      <h6 className="subtitle">Number of Sales</h6>
                      <h2 className="title">{salesCount}</h2>
                      <img
                        src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/confirmed.png"
                        alt=""
                        className="dashboard-icon"
                      />
                    </Link>
                  </div>
                  
                  
                </div>
              </div>
            </div>
        <div className="card">
          <div className="card-header shadow flex-wrap p-20px border-0">
            <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
            <form onSubmit={(e) => e.preventDefault()} className="w-100 mt-5">
              <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="start_date">
                      Start Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={filterStartDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterStartDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="end_date">
                      End Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={filterEndDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <form>
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Ex : Search by invoice ID"
                      aria-label="Search"
                      onChange={(e) => filterSaleList(e.target.value)}
                    />
                  </div>
                </form>
                {
                  loadingAllSales ? (
                    <button className="btn btn-primary"><RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  /></button>
                  ):(
                    <ReactToPrint
                      trigger={() => (
                        <button className="btn btn-primary">Print All Sales</button>
                      )}
                      content={() => salesListRef.current}
                    />
                  )
                }
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              {loadingSaleHistory ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2rem",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">Invoice ID</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th className="text-right">Total amount</th>
                      <th className="text-right">Amount Paid</th>
                      <th className="text-right">Outstanding balance</th>
                      <th>
                        <div className="text-center">Payment Status</div>
                      </th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody id="set-rows">
                    {saleHistory.map((sale, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td className="table-column-pl-0">{sale.sale_id}</td>
                        <td>{sale.date}</td>

                        <td>
                          {!sale?.customer ? (
                            "Walk in customer"
                          ) : (
                            <>
                              <div>
                                <a
                                  className="text-body text-capitalize font-medium"
                                  href=""
                                >
                                  {sale?.customer?.customer_name}
                                </a>
                              </div>
                              <div className="text-sm">
                                <Link
                                  to={`tel:${sale?.customer?.customer_phone_number}`}
                                >
                                  {sale?.customer?.customer_phone_number}
                                </Link>
                              </div>
                            </>
                          )}
                        </td>

                        <td className="text-right">GHS {sale.total_cost}</td>
                        <td className="text-right">GHS {sale.amount_paid}</td>
                        <td className="text-right">
                          GHS {sale.outstanding_balance}
                        </td>
                        <td className="text-capitalize text-center">
                          {sale.payment_status === "Full Payment" ? (
                            <span className="badge badge-soft-success">
                              {sale.payment_status}
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger">
                              {sale.payment_status}
                            </span>
                          )}
                        </td>
                        <td>
                          {deletingSale ? (
                            <div className="d-flex align-items-center justify-content-center col-sm-12">
                              <RotatingLines
                                visible={true}
                                height="96"
                                width="96"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          ) : (
                            <div className="btn--container justify-content-center">
                              <Link
                                className="action-btn btn--primary btn-outline-primary"
                                to={`/sales/${sale?.id}/details`}
                              >
                                <MdOutlineRemoveRedEye />
                              </Link>

                              <Link
                                className="action-btn btn--danger btn-outline-danger"
                                onClick={() => deleteSale(sale.id)}
                              >
                                <MdDeleteOutline />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {saleHistory.length <= 0 && !loadingSaleHistory && <NoData />}
            </div>

            {/* LIST TO PRINT */}
            <div className="hidden">
              <div ref={salesListRef} className="py-5 px-2">
                <div className="text-center border-b border-slate-200 pb-4 mb-4">
                  <h1 className="text-[35px] m-0">
                    {pharmacyDetails?.pharmacy_name}
                  </h1>
                  <p className="m-0">{pharmacyDetails?.pharmacy_location}</p>
                  <p className="m-0">{pharmacyDetails?.pharmacy_email} {pharmacyDetails?.pharmacy_phone}</p>

                </div>
                <p>Date Printed: {new Date().toLocaleDateString()}</p>
                <h1 className="text-center">Sales List</h1>
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">Invoice ID</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th className="text-right">Total amount</th>
                      <th className="text-right">Amount Paid</th>
                      <th className="text-right">Outstanding balance</th>
                      <th>
                        <div className="text-center">Payment Status</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody id="set-rows">
                    {salesList.map((sale, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="border border-slate-300">{index + 1}</td>
                        <td className="border border-slate-300">{sale.sale_id}</td>
                        <td>{sale.date}</td>

                        <td className="border border-slate-300">
                          {!sale?.customer ? (
                            "Walk in customer"
                          ) : (
                            <>
                              <div>
                                <a
                                  className="text-body text-capitalize font-medium"
                                  href=""
                                >
                                  {sale?.customer?.customer_name}
                                </a>
                              </div>
                              <div className="text-sm">
                                <Link
                                  to={`tel:${sale?.customer?.customer_phone_number}`}
                                >
                                  {sale?.customer?.customer_phone_number}
                                </Link>
                              </div>
                            </>
                          )}
                        </td>

                        <td className="text-right border border-slate-300">GHS {sale.total_cost}</td>
                        <td className="text-right border border-slate-300">GHS {sale.amount_paid}</td>
                        <td className="text-right border border-slate-300">
                          GHS {sale.outstanding_balance}
                        </td>
                        <td className="text-capitalize text-center border border-slate-300">
                          {sale.payment_status === "Full Payment" ? (
                            <span className="badge badge-soft-success">
                              {sale.payment_status}
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger">
                              {sale.payment_status}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <div className="d-flex gap-3">
                <button
                  className={`border border-primary py-2 px-4 ${
                    !prevPage
                      ? "text-primary bg-white"
                      : "text-white bg-primary"
                  }`}
                  onClick={handlePreviousPage}
                  disabled={!prevPage}
                >
                  Previous
                </button>
                {renderPageNumbers()}
                <button
                  className={`border border-primary py-2 px-4 ${
                    !nextPage
                      ? "text-primary bg-white"
                      : "text-white bg-primary"
                  }`}
                  onClick={handleNextPage}
                  disabled={!nextPage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SalesHistory;
